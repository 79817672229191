/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

/* --- Import theme variables */
@import 'src/@core/scss/base/bootstrap-extended/_variables';

// *** Dropdown menu
.dropdown-menu {
  .dropdown-item {
    width: 100%;
  }
}

// *** Show more less
.show-more-less-clickable {
  color: $primary;
}

// *** Carousel navigations
.carousel-control-prev {
  background: $primary;
  height: 30px;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  left: 20px;
  border-radius: 50%;
  opacity: 0.9;
}

// *** Carousel navigations
.carousel-control-next {
  background: $primary;
  height: 30px;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  right: 20px;
  border-radius: 50%;
  opacity: 0.9;
}

// *** Reports module
.reports-table-wrapper {
  .report-for-select {
    min-width: 226px;
    max-width: 226px;
  }

  .react-dataTable {
    .view-post-info {
      color: $primary;
      text-decoration: none;
    }
  }
}

// *** Post detail modal
.post-detail-modal {

  .avatar-content {
    height: 42px;
    width: 42px;
  }

  .post-media-container {
    .post-media-wrapper {
      width: 100%;

      .carousel {
        width: 100%;
        height: 100%;

        .carousel-indicators {
          margin-bottom: 2rem;
        }

        .carousel-item {
          .img-fluid {
            width: 100% !important;
            height: calc(100vh - 250px) !important;
            object-fit: cover;
          }

          .react-player-video {
            width: 100% !important;
            height: calc(100vh - 250px) !important;
          }
        }
      }
    }
  }
}

.invalid-feedback {
  display: block !important;
}

.link {
  color: $primary;
  cursor: pointer;
}

.linkPopup {
  cursor: pointer;
}

.earingText {
  padding: 4px;
  background-color: rgba(40, 199, 111, 0.16);
  font-size: 0.8125rem;
  border-radius: 4px;
  font-weight: 500;
}

.select-text {
  min-width: 226px;
  max-width: 226px;
}

.react-dataTable {
  .rdt_TableCell {
    & > div:first-child {
      white-space: initial !important;
      overflow: initial !important;
      text-overflow: unset !important;
    }
  }

  .rdt_TableCell {
    height: auto !important;
    padding: 0.72rem 1.5rem;
  }
}

.showText {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.customWidget {
  .session-info {
    h5 {
      display: flex;
      align-items: center;
      justify-content: center;

      span:first-child {
        font-size: 2.0243rem;
      }
    }
  }
}

.modal-view-subscribers {
  .card {
    margin-bottom: 0;
  }
}

.chart-no-record-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h4 {
    width: 100%;
    display: flex;
    justify-content: center;
    max-width: 350px;
    font-weight: 600;
  }
}

#dashboard-analytics {
  .demo-inline-spacing {
    .form-check {
      margin-top: 0.5rem !important;
    }
  }
}

.user-detail-tabs {
  ul.user-tabs-container {
    white-space: nowrap;
    flex-wrap: nowrap;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;

    //Add scroll on top instead bottom
    transform: rotateX(180deg);

    & > * {
      //Reset direction of all content
      transform: rotateX(180deg);
    }

    li.nav-item {
      display: inline-block;
      margin-bottom: 10px;
    }
  }
}

.yh-date-range-picker {
  width: 172px;
}

.yh-date-picker {
  width: 110px;
}

//Flatpicker customisation
.yh-flatpickr-container {
  display: flex;
  align-items: center;

  .form-control[readonly] {
    cursor: pointer;
    background-color: #fff;
  }

  .flatpickr-input {
    min-height: 38px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:placeholder-shown {
      font-size: 12px;
    }
  }

  .clear-icon-container {
    padding: 8px;
    display: flex;
    align-items: center;
    border: 1px solid;
    border-color: $input-border-color;
    border-left: 0 !important;
    border-radius: 0 0.357rem 0.357rem 0;
    cursor: pointer;
  }
}

.cursor-no-allowed {
  cursor: not-allowed;
}

.pointer-events-none {
  pointer-events: none;
}

.yh-scrollable-tab {

  .rts___nav___btn___container {
    display: flex;
    align-items: center;
    justify-content: center;

    .rts___btn {
      width: 40px;
      height: 40px;
    }
  }

  .rts___tab {
    padding: 0.786rem 1.5rem;
    border: 1px solid transparent;
    background: none;
    border-radius: 0.358rem;
    color: $headings-color;

    &.rts___tab___selected {
      border-color: #ee1668;
      box-shadow: 0 4px 10px -4px rgba(238, 22, 104, .65);
      color: $white;
    }
  }

  .rts___tab___selected {
    background-color: $primary;
  }

  .rts___nav___btn:hover {
    background-color: $primary;
  }
}

.user-detail-tabs {
  .payout-table-wrapper, .user-earning-spending-wrapper {
    #search-input {
      min-width: 160px!important;
    }
  }
}
